// @flow
import { type JSSThemeDefaultLiveType } from '../JSSThemeDefaultLive';

export const JSSThemeLive60400: JSSThemeDefaultLiveType = (themeVars) => {
  const {
    colors: {
      colorTypes: { background3, white },
    },
  } = themeVars;
  return {
    LiveMenuEvent: {
      LiveEventRanked: {
        background: background3,
      },
      LiveEventRankedDesktop: {
        background: '#0a1a28 !important',
      },
      LiveIcon: {
        fill: white,
      },
    },
    LiveMenuEventHeading: {
      LiveIcon: {
        fill: white,
      },
    },
    LiveMenuRankedEvent: {
      LiveIcon: {
        fill: white,
      },
    },
  };
};
