// @flow
import { type JSSThemeDefaultEventSliderType } from '../JSSThemeDefaultEventSlider';

export const JSSThemeEventSlider60400: JSSThemeDefaultEventSliderType = (
  themeVars
) => {
  const {
    colors: {
      colorTypes: { background1 },
    },
  } = themeVars;
  return {
    EventSlide: {
      root: {
        background: background1,
      },
    },
  };
};
