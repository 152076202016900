// @flow
import { type JSSThemeDefaultSearchType } from '../JSSThemeDefaultSearch';

export const JSSThemeSearch60400: JSSThemeDefaultSearchType = (themeVars) => {
  const {
    colors: {
      colorTypes: { text3, primary },
    },
    margins,
  } = themeVars;
  return {
    SearchBar: {
      iconContainer: {
        color: text3,
      },
      resultContainer: {
        padding: '0',
        maxHeight: 'calc(100vh - 93px - 40px)',
      },
    },
    SportsbookSearchResult: {
      sportsbookSearchResultRoot: {
        '&.mobile': {
          padding: margins.md,
        },
      },
      title: {
        color: '#fff',
      },
      sportsbookSearchResultOutcomesWrap: {
        marginLeft: -margins.xs,
        marginRight: -margins.xs,
        color: '#fff',
      },
      event: {
        background: 'transparent',
      },
      description: {
        color: '#fff',
        paddingLeft: 0,
        paddingRight: 0,
      },
      marketCount: {
        background: 'transparent',
        color: primary,
        fontWeight: 'bold',
        '&:hover': {
          color: '#fff',
        },
      },
    },
  };
};
