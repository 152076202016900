// @flow
import { LoadableComponentWrapper } from '../../components/components/LoadableComponentWrapper';
import { createKoltronApp } from '../createKoltronApp';
import {
  JSSTheme60400,
  JSSThemeVariables60400,
  getJSSThemePatterns60400,
} from './JSSTheme60400';
import { GeoblockPage60400 } from './GeoblockPage60400';
// $FlowIgnore
import '../../../styles/application-koltron.scss';

createKoltronApp({
  DesktopComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullDesktop60400')).FullDesktop60400,
  }),
  MobileComponent: LoadableComponentWrapper({
    loader: async () => (await import('./FullMobile60400')).FullMobile60400,
  }),
  JSSTheme: JSSTheme60400,
  JSSThemeVariables: JSSThemeVariables60400,
  JSSThemePatterns: getJSSThemePatterns60400,
  GeoblockPage: GeoblockPage60400,
});
