import { type JSSThemeDefaultSportsMenuType } from '../JSSThemeDefaultSportsMenu';

export const JSSThemeSportsMenu60400: JSSThemeDefaultSportsMenuType = (
  themeVars
) => {
  const {
    margins,
    colors: {
      //colorCombinations: {  },
      colorTypes: { white, text4, background3 },
    },
  } = themeVars;
  return {
    HorizontalSportsListUISmallIcons: {
      list: {
        paddingTop: 10,
        background: 'transparent',
      },
      sportLink: {
        color: text4,
        '&.active, &:hover': {
          color: white,
        },
      },
    },
    HorizontalSportsListIconSmall: {
      sportName: {
        fontWeight: 'bold',
      },
    },
    HorizontalSportsTabs: {
      rootSmallIcons: {
        height: 40,
        margin: 0,
      },
    },
    HorizontalSportsListUIBigIcons: {
      list: {
        background: 'transparent',
      },
      sportLink: {
        padding: ['0', margins.md, margins.xs],
      },
    },
    HorizontalLiveCasinoIcon: {
      icon: {
        color: background3,
        background: white,
      },
      title: {
        color: white,
        fontWeight: 'bold',
      },
    },
    HorizontalSportsListIconBig: {
      sportIcon: {
        color: white,
      },
      sportName: {
        fontWeight: 'bold',
      },
    },
  };
};
