// @flow
export const AMAZON_COMMON_BUCKET_URL_60400 = '//cdn.tornadobet.com';
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_DEFAULT = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/default.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_AMERICANFOOTBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/american_football.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_BASKETBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/basketball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_BEACHVOLLEYBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/beach_volleyball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_DARTS = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/darts.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_FUTSAL = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/futsal.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_HANDBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/handball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_ICEHOCKEY = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/hockey.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_FORMULA1 = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/motosports.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_RUGBY = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/rugby.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_FOOTBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/soccer.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_TABLETENNIS = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/tabletennis.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_TENNIS = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/tennis.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_VOLLEYBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/volleyball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_BASEBALL = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/baseball.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_ESPORTS = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/scoreboards/esports.jpg
)`;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_GREYHOUNDS = `url(
  ${AMAZON_COMMON_BUCKET_URL_60400}/cms/uploads/default/greyhounds.jpg
)`;

export const BACKGROUND_IMAGE_LIVE_BANNER_60400_BADMINTON =
  BACKGROUND_IMAGE_LIVE_BANNER_60400_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_BEACHSOCCER =
  BACKGROUND_IMAGE_LIVE_BANNER_60400_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_BOWLS =
  BACKGROUND_IMAGE_LIVE_BANNER_60400_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_FIELDHOCKEY =
  BACKGROUND_IMAGE_LIVE_BANNER_60400_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_SNOOKER =
  BACKGROUND_IMAGE_LIVE_BANNER_60400_DEFAULT;
export const BACKGROUND_IMAGE_LIVE_BANNER_60400_HORSERACING =
  BACKGROUND_IMAGE_LIVE_BANNER_60400_DEFAULT;
