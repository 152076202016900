// @flow
import { type JSSThemeDefaultBetgeniusType } from '../JSSThemeDefaultBetgenius';

export const JSSThemeBetgenius60400: JSSThemeDefaultBetgeniusType = () => {
  return {
    iframeWrapper: {
      display: 'flex',
      margin: 0,
      padding: 0,
      resize: 'both',
      background: '#0a1a28',
      overflow: 'hidden',
    },
    liveBetbuilderWrapper: {
      margin: 0,

      '@media (max-width: 1024px)': {
        margin: 0,
      },
    },
  };
};
