// @flow
import { type JSSThemeDefaultFormType } from '../JSSThemeDefaultForm';

export const JSSThemeForm60400: JSSThemeDefaultFormType = (themeVars) => {
  const {
    colors: {
      colorCombinations: { text2Neutral1 },
      colorTypes: {
        text2,
        shadow,
        border1,
        background4,
        background3,
        white,
        background5,
      },
    },
    margins,
    fontSizes,
    borderRadius,
  } = themeVars;
  return {
    Input: {
      root: {
        borderColor: border1,
        color: text2,
        background: shadow,
        borderRadius: borderRadius.lg,
        lineHeight: 'auto',
        height: 'auto',
        padding: [10, 10, 10, 14],
        '&::placeholder': {
          color: border1,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${background3} inset !important`,
          borderColor: border1,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
      betslipInput: {
        height: '30px',
        lineHeight: '30px',
        border: 'none',
        borderRadius: borderRadius.md,
        ...text2Neutral1,
        '&::placeholder': {
          color: background4,
        },
        '&:-webkit-autofill': {
          '-webkit-box-shadow': `0 0 0 30px ${background5} inset !important`,
          borderColor: `${background5} !important`,
          '-webkit-text-fill-color': `${text2} !important`,
        },
      },
    },
    Field: {
      root: {
        color: white,
        marginBottom: margins.xm * 2,
      },
      fieldLabel: {
        fontSize: fontSizes.lg,
        display: 'block',
        padding: [0, 0, margins.xs],
        fontWeight: 'bold',
        color: white,
      },
    },
  };
};
